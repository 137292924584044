import React, { useEffect, useRef, useCallback, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import osmtogeojson from 'osmtogeojson';

export default function Map({ accessToken, center, relayPoints, showPublicTransport, carriers }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const markersRef = useRef([]);

  const [publicTransportData, setPublicTransportData] = useState(null);
  const [lastFetchBbox, setLastFetchBbox] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const MIN_BBOX_CHANGE = 0.01;
  const MIN_ZOOM = 10;

  const lineFilter = [
    "all",
    ["==", "$type", "LineString"],
    ["has", "name"],
    ["has", "route"],
    ["!=", "public_transport", "platform"],
    ["!=", "railway", "platform"],
    ["!=", "railway", "abandoned"],
    ["!=", "railway", "disused"],
    ["!=", "railway", "construction"],
    ["!=", "service", "abandoned"],
    ["!=", "service", "disused"],
    ["!=", "service", "construction"]
  ];

  const stationFilter = [
    "all",
    ["==", "$type", "Point"],
    ["has", "name"],
    ["!=", "public_transport", "platform"],
    ["!=", "railway", "platform"],
    ["!=", "railway", "abandoned"],
    ["!=", "railway", "disused"],
    ["!=", "railway", "construction"],
    ["!=", "service", "abandoned"],
    ["!=", "service", "disused"],
    ["!=", "service", "construction"]
  ];

  const hasBboxSignificantlyChanged = useCallback((oldBbox, newBbox) => {
    if (!oldBbox) return true;
    const deltaLat = Math.abs(newBbox[1] - oldBbox[1]) + Math.abs(newBbox[3] - oldBbox[3]);
    const deltaLon = Math.abs(newBbox[0] - oldBbox[0]) + Math.abs(newBbox[2] - oldBbox[2]);
    return (deltaLat > MIN_BBOX_CHANGE || deltaLon > MIN_BBOX_CHANGE);
  }, []);

  const fetchPublicTransportData = useCallback(async (bbox) => {
    const [minLon, minLat, maxLon, maxLat] = bbox;
    const overpassQuery = `
      [out:json][timeout:25];
      (
        relation["route"~"^(subway|tram)$"](${minLat},${minLon},${maxLat},${maxLon});
        node["railway"="station"](${minLat},${minLon},${maxLat},${maxLon});
        node["railway"="tram_stop"](${minLat},${minLon},${maxLat},${maxLon});
        node["public_transport"="station"](${minLat},${minLon},${maxLat},${maxLon});
      );
      out body;
      >;
      out skel qt;
    `;
    const url = "https://overpass-api.de/api/interpreter";
    const response = await fetch(url, {
      method: 'POST',
      body: overpassQuery,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

    const osmData = await response.json();
    const geojson = osmtogeojson(osmData);
    return geojson;
  }, []);

  const addMarkers = useCallback(() => {
    if (!map.current || !map.current.loaded()) return;
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];

    relayPoints.forEach(point => {
      const carrier = carriers.find(c => c.slug === point.carrier);
      if (!carrier) return;
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = `url(${carrier.logo})`;
      el.style.width = '30px';
      el.style.height = '30px';
      el.style.backgroundSize = 'contain';
      el.style.backgroundRepeat = 'no-repeat';
      el.style.backgroundPosition = 'center';
      el.style.borderRadius = '50%';
      el.style.border = '2px solid white';
      el.style.boxShadow = '0 0 13px rgba(0,0,0,0.5)';

      const marker = new mapboxgl.Marker(el)
        .setLngLat([point.lon, point.lat])
        .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`
            <h3>${point.name}</h3>
            <p>${point.address}</p>
            <p>Distance: ${point.distance} km</p>
            <p>Transporteur: ${carrier.name}</p>
          `))
        .addTo(map.current);

      markersRef.current.push(marker);
    });
  }, [relayPoints, carriers]);

  const addOrUpdatePublicTransportLayers = useCallback((geojsonData) => {
    if (!map.current || !map.current.loaded()) return;

    const src = map.current.getSource('public_transport');
    if (src) {
      src.setData(geojsonData);
    } else {
      map.current.addSource('public_transport', {
        type: 'geojson',
        data: geojsonData
      });

      map.current.addLayer({
        id: 'transport-lines',
        type: 'line',
        source: 'public_transport',
        filter: lineFilter,
        paint: {
          'line-color': ['coalesce', ['get', 'colour'], '#000000'],
          'line-width': 6
        },
        layout: {
          'visibility': showPublicTransport ? 'visible' : 'none'
        }
      });

      map.current.addLayer({
        id: 'transport-stations',
        type: 'circle',
        source: 'public_transport',
        filter: stationFilter,
        paint: {
          'circle-radius': 6,
          'circle-color': '#FFFFFF',
          'circle-stroke-width': 1,
          'circle-stroke-color': '#000000'
        },
        layout: {
          'visibility': showPublicTransport ? 'visible' : 'none'
        }
      });

      map.current.on('click', 'transport-lines', (e) => {
        const feature = e.features[0];
        const name = feature.properties.name || 'Ligne sans nom';
        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(`<p>${name}</p>`)
          .addTo(map.current);
      });

      map.current.on('click', 'transport-stations', (e) => {
        const feature = e.features[0];
        const coordinates = feature.geometry.coordinates;
        const name = feature.properties.name || 'Station sans nom';
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(`<p>${name}</p>`)
          .addTo(map.current);
      });

      map.current.on('mouseenter', 'transport-lines', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'transport-lines', () => {
        map.current.getCanvas().style.cursor = '';
      });

      map.current.on('mouseenter', 'transport-stations', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'transport-stations', () => {
        map.current.getCanvas().style.cursor = '';
      });
    }
  }, [showPublicTransport, lineFilter, stationFilter]);

  // On n'initialise la carte qu'une seule fois, sans dépendre de showPublicTransport ou center
  useEffect(() => {
    mapboxgl.accessToken = accessToken;
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/light-v10', // On garde toujours le même fond
        center: center, // Position initiale, on n’y touche plus après
        zoom: 12
      });

      map.current.on('load', () => {
        map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
        addMarkers();
      });
    }

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
    // Pas de dépendances ici, la carte est créée une seule fois
  }, [accessToken, addMarkers]);

  // Met à jour les markers si relayPoints change (sans réinitialiser la carte)
  useEffect(() => {
    if (map.current && map.current.loaded()) {
      addMarkers();
    }
  }, [addMarkers]);

  // Ajuster la visibilité des couches sans recharger la carte
  useEffect(() => {
    if (map.current && map.current.loaded()) {
      const visibility = showPublicTransport ? 'visible' : 'none';
      if (map.current.getLayer('transport-lines')) {
        map.current.setLayoutProperty('transport-lines', 'visibility', visibility);
      }
      if (map.current.getLayer('transport-stations')) {
        map.current.setLayoutProperty('transport-stations', 'visibility', visibility);
      }
    }
  }, [showPublicTransport]);

  // Chargement initial des données au moment où showPublicTransport passe à true, sans bouger la carte
  useEffect(() => {
    const loadData = async () => {
      if (!showPublicTransport) return;
      if (!map.current || !map.current.loaded()) return;
      if (isFetching || publicTransportData) return;

      const zoom = map.current.getZoom();
      if (zoom < MIN_ZOOM) return; // trop dézoomé pour charger

      const bounds = map.current.getBounds();
      const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

      setIsFetching(true);
      try {
        const geojson = await fetchPublicTransportData(bbox);
        setPublicTransportData(geojson);
        addOrUpdatePublicTransportLayers(geojson);
      } catch (err) {
        console.error("Erreur Overpass:", err);
      } finally {
        setIsFetching(false);
      }
    };

    loadData();
  }, [showPublicTransport, isFetching, publicTransportData, fetchPublicTransportData, addOrUpdatePublicTransportLayers]);

  // Mise à jour des données lors des déplacements si showPublicTransport est actif
  useEffect(() => {
    if (!map.current) return;

    const onMoveEnd = async () => {
      if (!showPublicTransport) return;
      if (isFetching) return;
      if (!map.current || !map.current.loaded()) return;

      const zoom = map.current.getZoom();
      if (zoom < MIN_ZOOM) return;

      const bounds = map.current.getBounds();
      const newBbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
      if (!hasBboxSignificantlyChanged(lastFetchBbox, newBbox)) return;

      setLastFetchBbox(newBbox);
      setIsFetching(true);
      try {
        const geojson = await fetchPublicTransportData(newBbox);
        setPublicTransportData(geojson);
        addOrUpdatePublicTransportLayers(geojson);
      } catch (err) {
        console.error("Erreur Overpass:", err);
      } finally {
        setIsFetching(false);
      }
    };

    if (showPublicTransport) {
      map.current.on('moveend', onMoveEnd);
    } else {
      map.current.off('moveend', onMoveEnd);
    }

    return () => {
      map.current && map.current.off('moveend', onMoveEnd);
    };
  }, [
    showPublicTransport,
    lastFetchBbox,
    isFetching,
    hasBboxSignificantlyChanged,
    fetchPublicTransportData,
    addOrUpdatePublicTransportLayers
  ]);

  return <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />;
}
