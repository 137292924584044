import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function AutocompleteInput({ value, onChange, onSelect, placeholder, className }) {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [inputValue, setInputValue] = useState(value);
  const [isSelecting, setIsSelecting] = useState(false);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);

  const fetchSuggestions = useCallback(
    debounce((query) => {
      if (query.length > 2 && !isSelecting) {
        fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=5&type=municipality`)
          .then(response => response.json())
          .then(data => {
            setSuggestions(data.features.map(feature => ({
              label: feature.properties.label,
              context: feature.properties.context.split(',').slice(1).join(','),
              name: feature.properties.name,
              lat: feature.geometry.coordinates[1],
              lon: feature.geometry.coordinates[0]
            })));
          });
      } else {
        setSuggestions([]);
      }
    }, 300),
    [isSelecting]
  );

  useEffect(() => {
    if (!isSelecting) {
      fetchSuggestions(inputValue);
    }
  }, [inputValue, fetchSuggestions, isSelecting]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
    setSelectedIndex(-1);
    setIsSelecting(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex(prev => (prev < suggestions.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex(prev => (prev > 0 ? prev - 1 : -1));
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      e.preventDefault();
      handleSelection(suggestions[selectedIndex]);
    }
  };

  const handleSelection = (selected) => {
    setIsSelecting(true);
    onSelect(selected);
    setInputValue(selected.label);
    setSuggestions([]);
    setSelectedIndex(-1);
  };

  useEffect(() => {
    if (suggestionsRef.current) {
      const selectedElement = suggestionsRef.current.children[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [selectedIndex]);

  return (
    <div className="relative">
      <Input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        className={className}
      />
      {suggestions.length > 0 && !isSelecting && (
        <Card className="absolute z-10 w-full mt-1 max-h-60 overflow-auto" ref={suggestionsRef}>
          {suggestions.map((suggestion, index) => (
            <Button
              key={suggestion.label}
              variant="ghost"
              className={`w-full justify-start h-auto ${index === selectedIndex ? 'bg-accent' : ''}`}
              onClick={() => handleSelection(suggestion)}
            >
              <div className="text-start">
                <div className="">{suggestion.label}</div>
                <div className="text-sm text-muted-foreground">{suggestion.context}</div>
              </div>
            </Button>
          ))}
        </Card>
      )}
    </div>
  );
}

