import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader,  } from "../components/ui/card";
import { Switch } from "../components/ui/switch";
import { carriers, getCarrierBySlug } from '../data/carrierData';
import Map from '../components/Map';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoidG9tYmVyZ2VyIiwiYSI6ImNsOTBhNW85ZjBiMXUzb3BtcjB6MGcwd3gifQ.-w-PsPHNqV8hc3rfc6GqLw';

export default function SearchRelay() {
  const location = useLocation();
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState(48.8566);
  const [lon, setLon] = useState(2.3522);
  const [selectedCarriers, setSelectedCarriers] = useState(carriers.map(c => c.slug));
  const [showPublicTransport, setShowPublicTransport] = useState(false);
  const [relayPoints, setRelayPoints] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setAddress(params.get('address') || 'Paris');
    setLat(parseFloat(params.get('lat')) || 48.8566);
    setLon(parseFloat(params.get('lon')) || 2.3522);

    fetchRelayPoints();
  }, [location]);

  const fetchRelayPoints = () => {
    const mockApiResponse = [
      {
        id: 1,
        name: "Relay Point Louvre",
        address: "1 Rue de Rivoli, 75001 Paris",
        lat: 48.8606,
        lon: 2.3376,
        distance: 1.2,
        carrier: "chronopost"
      },
      {
        id: 2,
        name: "Pickup Station Marais",
        address: "35 Rue des Archives, 75004 Paris",
        lat: 48.8592,
        lon: 2.3567,
        distance: 1.5,
        carrier: "dhl"
      },
      {
        id: 3,
        name: "Parcel Shop Montmartre",
        address: "18 Rue Lepic, 75018 Paris",
        lat: 48.8865,
        lon: 2.3344,
        distance: 2.8,
        carrier: "ups"
      },
      {
        id: 4,
        name: "Delivery Point Bastille",
        address: "11 Rue de la Roquette, 75011 Paris",
        lat: 48.8539,
        lon: 2.3738,
        distance: 2.1,
        carrier: "fedex"
      },
      {
        id: 5,
        name: "Pickup Location Latin Quarter",
        address: "15 Rue Mouffetard, 75005 Paris",
        lat: 48.8416,
        lon: 2.3508,
        distance: 3.2,
        carrier: "la-poste"
      }
    ];

    setRelayPoints(mockApiResponse);
  };

  const toggleCarrier = (carrierSlug) => {
    setSelectedCarriers(prev => 
      prev.includes(carrierSlug) 
        ? prev.filter(slug => slug !== carrierSlug)
        : [...prev, carrierSlug]
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Points relais près de {address}</h1>
      
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-1/3">
          <Card>
            <CardHeader>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-3">Sélectionnez les transporteurs</h3>
                <div className="flex flex-wrap gap-3 transition-colors">
                  {carriers.map((carrier) => (
                    <Button
                      key={carrier.slug}
                      variant={selectedCarriers.includes(carrier.slug) ? "default" : "outline"}
                      className={`flex items-center gap-2 p-2 transition-colors ${
                        selectedCarriers.includes(carrier.slug)
                          ? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                          : 'bg-background hover:bg-gray-100'
                      }`}
                      onClick={() => toggleCarrier(carrier.slug)}
                      aria-pressed={selectedCarriers.includes(carrier.slug)}
                    >
                      <img src={carrier.logo} alt="" className="w-8 h-8 rounded-sm" />
                      <span className="font-medium">{carrier.name}</span>
                    </Button>
                  ))}
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-3">Points relais disponibles</h3>
                {relayPoints.length === 0 ? (
                  <p>Chargement des points relais...</p>
                ) : (
                  relayPoints.map((point) => (
                    <div key={point.id} className="mb-4 p-4 border rounded hover:bg-secondary transition-colors">
                      <h4 className="font-semibold">{point.name}</h4>
                      <p className="text-sm text-muted-foreground">{point.address}</p>
                      <p className="text-sm">Distance: {point.distance} km</p>
                      <p className="text-sm font-medium mt-1">
                        Transporteur: {getCarrierBySlug(point.carrier)?.name}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="w-full lg:w-2/3">
          <Card className="h-[800px]">
            <CardContent className="h-full relative">
              <div className="absolute top-4 right-4 z-10 bg-white p-2 rounded shadow">
                <div className="flex items-center gap-2">
                  <Switch
                    checked={showPublicTransport}
                    onCheckedChange={setShowPublicTransport}
                  />
                  <span>Transport en commun</span>
                </div>
              </div>
              <Map
                accessToken={MAPBOX_ACCESS_TOKEN}
                center={[lon, lat]}
                relayPoints={relayPoints}
                showPublicTransport={showPublicTransport}
                carriers={carriers}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

