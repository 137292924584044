import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from "../components/ui/card";
import { Package, MapPin, TrendingUp, Truck, Newspaper, HelpCircle } from 'lucide-react';
import { carriers, getCarrierBySlug } from '../data/carrierData';
import { AutocompleteInput } from '../components/Autocomplete';

export default function HomePage() {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [carrier, setCarrier] = useState('');
  const [address, setAddress] = useState({ label: '', name: '', lat: null, lon: null });

  const handleFindRelay = (e) => {
    e.preventDefault();
    if (address.name && address.lat && address.lon) {
      const searchParams = new URLSearchParams({
        address: address.name,
        lat: address.lat,
        lon: address.lon
      });
      //navigate(`/relay/search?${searchParams.toString()}`);
      //navigate not working, use something else
      window.location.href = `/relay/search?${searchParams.toString()}`;
      
    } else {
      alert("Veuillez sélectionner une adresse valide.");
    }
  };


  const handleTrackPackage = (e) => {
    e.preventDefault();
    const selectedCarrier = getCarrierBySlug(carrier);
    if (selectedCarrier && trackingNumber) {
      window.open(`${selectedCarrier.trackingUrlPrefix}${trackingNumber}`, '_blank');
    } else {
      alert("Veuillez sélectionner un transporteur et entrer un numéro de suivi.");
    }
  };

  const blogPosts = [
    { title: "Comment choisir le meilleur point relais pour vos livraisons", date: "2023-05-15" },
    { title: "Les avantages du suivi de colis en temps réel pour les e-commerçants", date: "2023-05-10" },
    { title: "5 astuces pour optimiser vos livraisons et réduire les coûts", date: "2023-05-05" },
  ];

  const largestCities = [
    { name: "Paris", slug: "paris", description: "Capitale de la France, hub logistique majeur" },
    { name: "Marseille", slug: "marseille", description: "Principal port maritime de France" },
    { name: "Lyon", slug: "lyon", description: "Carrefour économique au cœur de l'Europe" },
    { name: "Toulouse", slug: "toulouse", description: "Centre aéronautique et spatial" },
    { name: "Nice", slug: "nice", description: "Porte d'entrée de la Côte d'Azur" },
    { name: "Nantes", slug: "nantes", description: "Métropole dynamique de l'Ouest" },
    { name: "Montpellier", slug: "montpellier", description: "Pôle d'innovation du Sud" },
    { name: "Strasbourg", slug: "strasbourg", description: "Carrefour européen stratégique" },
  ];

  return (
    <>
      <Helmet>
        <title>Colis Facile - Suivi de colis et recherche de points relais en France</title>
        <meta name="description" content="Suivez vos colis en temps réel et trouvez les points relais les plus proches avec Colis Facile. Service gratuit pour La Poste, Chronopost, DHL et plus." />
        <link rel="canonical" href="https://www.colis-facile.com" />
        <meta property="og:title" content="Colis Facile - Suivi de colis et points relais" />
        <meta property="og:description" content="Suivez vos colis et trouvez des points relais facilement avec Colis Facile. Service gratuit pour tous les transporteurs majeurs." />
        <meta property="og:url" content="https://www.colis-facile.com" />
        <meta property="og:type" content="website" />
      </Helmet>
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-center mb-8">Suivi de Colis et Recherche de Points Relais</h1>
        
        <section className="mb-12">
          <p className="text-lg text-center mb-8">
            Bienvenue sur Colis Facile, votre solution tout-en-un pour le suivi de colis et la recherche de points relais en France. 
            Simplifiez votre expérience de livraison en quelques clics.
          </p>
        </section>

        <div className="grid md:grid-cols-2 gap-8 mb-24">
          <Card className="shadow-lg border-2 border-primary">
            <CardHeader>
              <CardTitle className="flex items-center text-2xl">
                <Package className="mr-2 h-8 w-8" />
                Suivre un colis
              </CardTitle>
              <CardDescription className="text-lg">Localisez votre colis en temps réel</CardDescription>
            </CardHeader>
            <CardContent>
              <form onSubmit={handleTrackPackage} className="space-y-4">
                <Input
                  type="text"
                  placeholder="Numéro de suivi"
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                  required
                  aria-label="Numéro de suivi du colis"
                  className="text-lg p-6"
                />
                <Select onValueChange={setCarrier} required>
                  <SelectTrigger className="text-lg p-6">
                    <SelectValue placeholder="Choisir un transporteur" />
                  </SelectTrigger>
                  <SelectContent>
                    {carriers.map((carrier) => (
                      <SelectItem key={carrier.slug} value={carrier.slug}>{carrier.name}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button type="submit" className="w-full text-lg p-6">Suivre le colis</Button>
              </form>
            </CardContent>
          </Card>
          
          <Card className="shadow-lg border-2 border-primary">
            <CardHeader>
              <CardTitle className="flex items-center text-2xl">
                <MapPin className="mr-2 h-8 w-8" />
                Trouver un point relais
              </CardTitle>
              <CardDescription className="text-lg">Localisez les points relais près de chez vous</CardDescription>
            </CardHeader>
            <CardContent>
              <form onSubmit={handleFindRelay} className="space-y-4">
              <AutocompleteInput
          value={address.label}
          onChange={(value) => setAddress({ label: value, name: '', lat: null, lon: null })}
          onSelect={(selected) => setAddress(selected)}
          placeholder="Ville ou code postal"
          className="text-lg p-6"
        />
                        <Button type="submit" className="w-full text-lg p-6">Rechercher des points relais</Button>
              </form>
            </CardContent>
          </Card>
        </div>

<section className="mb-12">
  <div className="flex items-center justify-between mb-6">
    <h2 className="text-3xl font-semibold flex items-center">
      Suivi de colis par transporteur
    </h2>
    <Link to="/suivi" className="text-primary hover:underline">Voir tous les transporteurs</Link>
  </div>
  <p className="text-lg mb-6">Suivez vos colis avec précision, quel que soit le transporteur choisi. Colis Facile s'intègre avec les plus grands noms de la livraison pour vous offrir un suivi en temps réel.</p>
  <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
    {carriers.map((carrier) => (
      <Card key={carrier.slug} className="hover:shadow-lg transition-shadow duration-300">
        <Link to={`/suivi/${carrier.slug}`} className="block h-full">
          <CardHeader>
            <img src={carrier.logo} alt={`Logo ${carrier.name}`} className="h-12 mb-2 w-12 rounded-lg" />
            <CardTitle>{carrier.name}</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-gray-600">{carrier.description}</p>
          </CardContent>
          <CardFooter>
            <Button variant="link" className="text-primary">Suivre un colis {carrier.name}</Button>
          </CardFooter>
        </Link>
      </Card>
    ))}
  </div>
</section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6">Points relais dans les grandes villes</h2>
          <p className="text-lg mb-6">Trouvez facilement des points relais dans les principales villes de France pour vos envois et réceptions de colis.</p>
          <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
            {largestCities.map((city) => (
              <Card key={city.slug} className="hover:shadow-lg transition-shadow duration-300">
                <Link to={`#${city.slug}`} className="block h-full">
                  <CardHeader>
                    <CardTitle>{city.name}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-sm text-gray-600">{city.description}</p>
                  </CardContent>
                  <CardFooter>
                    <Button variant="link" className="text-primary">Voir les points relais</Button>
                  </CardFooter>
                </Link>
              </Card>
            ))}
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6">Pourquoi choisir Colis Facile ?</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <TrendingUp className="mr-2" />
                  Suivi en temps réel
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p>Suivez vos colis en temps réel avec des mises à jour instantanées sur leur localisation. Notre système s'intègre avec tous les grands transporteurs pour vous fournir les informations les plus récentes.</p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <MapPin className="mr-2" />
                  Large réseau de points relais
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p>Accédez à un vaste réseau de points relais partout en France. Trouvez facilement le point de retrait ou de dépôt le plus proche de chez vous ou de votre lieu de travail.</p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Truck className="mr-2" />
                  Suivi multi-transporteurs
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p>Colis Facile prend en charge le suivi de colis pour tous les grands transporteurs en France, notamment La Poste, Chronopost, DHL, UPS, et FedEx. Peu importe le service de livraison que vous utilisez, nous avons la solution.</p>
              </CardContent>
            </Card>
          </div>
        </section>

        <section className="mb-12">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-3xl font-semibold flex items-center">
              <Newspaper className="mr-2" />
              Derniers articles du blog
            </h2>
            <Link to="/blog" className="text-primary hover:underline">Voir tous les articles</Link>
          </div>
          <div className="grid md:grid-cols-3 gap-6">
            {blogPosts.map((post, index) => (
              <Card key={index}>
                <CardHeader>
                  <img src={`https://placehold.co/200x400`} alt="Article placeholder" className="w-full h-40 object-cover rounded-t-lg" />
                  <CardTitle className="mt-4">{post.title}</CardTitle>
                  <CardDescription>{post.date}</CardDescription>
                </CardHeader>
                <CardContent>
                  <p>Découvrez nos conseils et astuces pour optimiser vos livraisons et mieux comprendre le monde de la logistique e-commerce.</p>
                  <Button variant="link" className="mt-2 p-0">Lire la suite</Button>
                </CardContent>
              </Card>
            ))}
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6 flex items-center">
            <HelpCircle className="mr-2" />
            Foire Aux Questions
          </h2>
          <div className="space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Comment fonctionne le suivi de colis ?</CardTitle>
              </CardHeader>
              <CardContent>
                <p>Notre système de suivi de colis se connecte en temps réel aux bases de données des transporteurs. Entrez simplement votre numéro de suivi et sélectionnez le transporteur pour obtenir les dernières informations sur l'état et la localisation de votre colis.</p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Puis-je suivre plusieurs colis à la fois ?</CardTitle>
              </CardHeader>
              <CardContent>
                <p>Oui, vous pouvez suivre autant de colis que vous le souhaitez. Notre plateforme est conçue pour gérer le suivi de multiples colis simultanément, vous permettant de garder un œil sur toutes vos livraisons en un seul endroit.</p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Le service Colis Facile est-il gratuit ?</CardTitle>
              </CardHeader>
              <CardContent>
                <p>Oui, notre service de base pour le suivi de colis et la recherche de points relais est entièrement gratuit.</p>
              </CardContent>
            </Card>
          </div>
        </section>
      </main>
    </>
  );
}